<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ name }}
            </p>
            <span class="user-status">{{ $store.getters['auth/getUserData'] ? `Organization Admin: ${$store.getters['mainEntity/getEntityName']}` : $store.getters['mainEntity/getEntityName'] }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="avatarText(name)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{name:'home'}"
        >
          <feather-icon
            size="16"
            icon="EditIcon"
            class="mr-50"
          />
          <span>Change Entity</span>
        </b-dropdown-item>
        <b-dropdown-item
          :to="{name:'change-password'}"
        >
          <feather-icon
            size="16"
            icon="EditIcon"
          />
          <span>Change Password</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import signOut from '@/common/compositions/common/signOut'

export default {
  components: {
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    name: {
      get() {
        return this.$store.getters['auth/getUserData'] ? this.$store.getters['auth/getUserData'].username : 'Super Admin'
      },
    },
  },
  setup() {
    const { logout } = signOut()
    return {
      logout,
    }
  },

  methods: {
    avatarText,
  },
}
</script>
