export default [
  {
    header: 'System Admin',
    type: 'admin',
  },
  {
    title: 'Admin Dashboard',
    route: 'admin-dashboard',
    icon: 'BarChart2Icon',
    resource: 'admin-dashboard',
    action: 'read',
    type: 'admin',
  },
  {
    title: 'Mobile Update',
    route: 'add-mobile-update',
    icon: 'PhoneIcon',
    resource: 'mobile-updates',
    action: 'add',
    type: 'admin',
  },
  {
    title: 'Islamic Orgs',
    route: 'inpos-list',
    icon: 'GridIcon',
    resource: 'EntitiesController',
    action: 'menu',
    type: 'admin',

  },
  {
    title: 'Commercial',
    route: 'business-list',
    icon: 'ShoppingBagIcon',
    resource: 'CommercialController',
    action: 'menu',
    type: 'admin',
  },
  {
    title: 'Live Management',
    route: 'live-management-list',
    icon: 'VideoIcon',
    resource: 'LiveController',
    action: 'show',
    type: 'admin',
  },
  {
    title: 'Notifications',
    route: 'list-notifications',
    icon: 'BellIcon',
    resource: 'notifications',
    action: 'list',
  },
  {
    title: 'General Settings',
    icon: 'SettingsIcon',
    type: 'admin',
    children: [
      {
        title: 'Tags',
        route: 'tags',
        resource: 'other',
        action: 'menu',
      },
      {
        title: 'Languages',
        route: 'languages',
        resource: 'other',
        action: 'menu',
      },
      {
        title: 'Classifications',
        route: 'classifications',
        resource: 'other',
        action: 'menu',
      },
      {
        title: 'Sub Classifications',
        route: 'sub-classifications',
        resource: 'other',
        action: 'menu',
      },
      {
        title: 'Donation Categories',
        route: 'donation-categories',
        resource: 'other',
        action: 'menu',
      },
    ],
  },
  {
    header: 'Islamic Organization Admin',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Dashboard',
    icon: 'BarChart2Icon',
    route: 'inpo-dashboard',
    resource: 'DashboardController',
    action: 'get-dashboard-statistics',
  },
  {
    title: 'Permissions',
    icon: 'KeyIcon',
    children: [
      {
        title: 'Staff',
        route: 'portal-user-list',
        resource: 'permissions',
        action: 'portal-users-list',
      },
      {
        title: 'Roles',
        route: 'roles-list',
        resource: 'permissions',
        action: 'roles-list',
      },
    ],
  },
  {
    title: 'Mobile Users',
    icon: 'UserIcon',
    children: [
      {
        title: 'List',
        route: 'mobile-users-list',
        icon: 'UserIcon',
        resource: 'UsersController',
        action: 'show',
      },
      {
        title: 'OTP',
        route: 'mobile-users-otp',
        icon: 'KeyIcon',
        resource: 'UsersController',
        action: 'getUserDetailsWithOTP',
      },
    ],
  },
  {
    title: 'Prayers',
    icon: 'MoonIcon',
    children: [
      {
        title: 'All Prayers',
        route: 'prayers-time-list',
        resource: 'prayers',
        action: 'viewPrayers',
      },
      {
        title: 'Update Iqama',
        route: 'update-iqama',
        resource: 'prayers',
        action: 'viewUpdateIqama',
      },
      {
        title: 'Get Azan',
        route: 'get-azan',
        resource: 'prayers',
        action: 'getAzan',
      },
      {
        title: 'Import or Export',
        route: 'import-export',
        resource: 'prayers',
        action: 'importAndExport',
      },
    ],
  },
  {
    title: 'Announcements',
    route: 'announcements-list',
    icon: 'MicIcon',
    resource: 'ActivityController',
    action: 'show',
  },
  {
    title: 'Booking Management',
    icon: 'MoonIcon',
    children: [
      {
        title: 'Events Management',
        route: 'prayer-bookings-list',
        icon: 'MoonIcon',
        resource: 'PrayerBookingController',
        action: 'show',
      },
      {
        title: 'Booking Requests',
        icon: 'CalendarIcon',
        route: 'booking-requests',
        resource: 'BookingActionController',
        action: 'show',
      },
      {
        title: 'Scan QR',
        icon: 'ApertureIcon',
        route: 'scan-qr',
        resource: 'PrayerBookingController',
        action: 'getBookingDetailsByQRCode',
      },
    ],
  },
  {
    title: 'Donation Management',
    route: 'donations-list',
    icon: 'DollarSignIcon',
    resource: 'UsersController',
    action: 'getUserDonationForEntity',
  },
  {
    title: 'Transactions',
    route: 'transactions-list',
    icon: 'DollarSignIcon',
    resource: 'TransactionController',
    action: 'index',
  },
]
